<template>
  <b-card
    no-body
    :class="{ open: visible }"
  >
    <b-card-header
      ref="myCollapsible"
      :class="{ collapsed: visible }"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
      @click="collapseOpen(!visible, $event)"
    >
      <slot name="header">
        <!-- <span class="">{{ avatar }}</span> -->
        <span class="lead collapse-title">
          <b-media class="media">
            <template #aside>
              <b-avatar
                v-if="isAvatar"
                size="32"
                :src="src"
              />
            </template>
            <div>
              <b-link>
                <span class="d-block font-weight-bolder text-nowrap">
                  {{ title }}
                </span>
              </b-link>
            </div>
          </b-media>
        </span>
        <div>
          <feather-icon
            :id="id"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-toggle.edit-profile
            icon="Edit2Icon"
            class="cursor-pointer"
            size="16"
            style="margin: 8px"
            @click="editFunc($event)"
          />
          <feather-icon
            :id="id"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :icon="icons"
            class="cursor-pointer"
            style=" padding: 6px"
            size="28"
            @click="deleteFunc($event)"
          />
        </div>
      </slot>
    </b-card-header>
    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body style="padding: 0px !important">
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BMedia,
  BAvatar,
  BLink,
  VBToggle,

} from 'bootstrap-vue'
import { required } from '@validations'
import { v4 as uuidv4 } from 'uuid'
import Ripple from 'vue-ripple-directive'
import 'vuesax/dist/vuesax.css'
import Vuesax from 'vuesax'
import Vue from 'vue'
import 'swiper/css/swiper.css'
import '@/assets/images/simiicons/edit.svg'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BMedia,
    BAvatar,
    BLink,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['isVisible', 'title', 'icons', 'icon', 'isAvatar', 'src', 'id', 'edit'],
  data() {
    return {
      disabledButton: false,
      active: false,
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
      // validation
      required,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion
        ? `accordion-${this.$parent.collapseID}`
        : null
    },
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  methods: {
    collapseOpen(val, e) {
      e.preventDefault()
      e.stopPropagation()
      this.visible = val
    },
    collapseClose() {
      if (this.openOnHover) {
        this.visible = false
      }
    },
    deleteFunc(e) {
      e.preventDefault()
      e.stopPropagation()
      this.$emit('deleteProfile', { id: this.id })
    },
    editFunc(e) {
      e.preventDefault()
      e.stopPropagation()
      this.$emit('editProfile', { id: this.id, src: this.src, title: this.title })
    },
  },
}
</script>
<style scoped>

</style>
