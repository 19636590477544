<template>
  <b-modal
    ref="edit-profile-modal-1"
    centered
    hide-footer
    hide-header
    body-bg-variant="red"
  >
    <div class="d-flex justify-content-center align-content-center align-items-center">
      <b-row style="display: block ">
        <div class="closediv">
          <b-button
            variant="transparent"
            class="closebtn"
            @click="hide()"
          >
            <feather-icon
              icon="XIcon"
              class="cursor-pointer"
              size="16"
            />
          </b-button>
        </div>
        <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center mt-1">
          <p class="font-weight-bolder heading1">
            Edit Profile
          </p>
        </div>
        <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center position-relative m-0">
          <b-img
            :src="profileImgSrc? profileImgSrc : userImage"
            class="addprofile"
            rounded
            alt="data.json"
          />
          <span class="upload">
            <b-button
              variant="link"
              @click="inputFileClicked"
            >
              <b-img
                :src="require('@/assets/images/simiicons/Edit(2).svg')"
                rounded
                alt="data.json"
              />
            </b-button>
          </span>
        </div>
        <div class="d-inline-block m-1 d-flex justify-content-center align-content-center align-items-center">
          <div class="form">
            <validation-observer ref="editProfileForm">
              <b-form
                class="mt-2"
                @submit.prevent
              >
                <input
                  id="fileUpload"
                  type="file"
                  accept="image/*"
                  hidden
                  @change="uploadImage($event)"
                >
                <div>
                  <b-form-group
                    text-bolder
                    label="Profile Name"
                    class="label"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="userProfile"
                      rules="required"
                    >
                      <b-form-input
                        id="input-1"
                        v-model="userProfile"
                        placeholder="Enter Profile Name"
                        class="input1"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <!-- submit buttons -->
                <div
                  class="form-group block"
                >
                  <b-button
                    block
                    variant="primary"
                    type="submit"
                    class="button"
                    :disabled="!active"
                    @click="editProfileSave(id,$event)"
                  >
                    <div
                      v-if="spinner"
                      class="spinner"
                    >
                      <b-spinner
                        small
                      />
                      <span class="sr-only">Loading...</span>
                    </div>
                    Save
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import {
  BRow,
  BButton,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showErrorMessage, showToast } from '@/common/global/functions'

export default {
  components: {
    BRow,
    BButton,
    BImg,
    BForm,
    BFormGroup,
    BFormInput,
    BSpinner,
    // validations
    ValidationProvider,
    ValidationObserver,

  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['id', 'src', 'title'],
  data() {
    return {
      userProfile: this.title,
      userImage: this.src,
      uploadedProfileImage: null,
      profileImgSrc: '',
      active: true,
      spinner: false,
    }
  },
  methods: {
    show() {
      this.userProfile = this.title
      this.profileImgSrc = null
      this.userImage = this.src
      this.$refs['edit-profile-modal-1'].show()
    },
    hide() {
      this.$refs['edit-profile-modal-1'].hide()
      this.userProfile = this.title
      this.profileImgSrc = null
      this.userImage = this.src
    },
    editProfileSave(id) {
      this.$refs.editProfileForm.validate().then(async success => {
        if (success) {
          this.spinner = true
          this.active = false
          await this.editProfile(id)
          this.spinner = false
          this.active = true
          this.hide()
          await this.$emit('getAllProfiles')
        }
      })
    },
    inputFileClicked(event) {
      document.getElementById('fileUpload').click(event)
    },
    uploadImage(event) {
      const obj = event.target.files[0]
      this.uploadedProfileImage = obj
      this.profileImgSrc = URL.createObjectURL(obj)
    },
    async editProfile(id) {
      const formData = new FormData()
      formData.append('profile_name', this.userProfile)
      formData.append('image', this.uploadedProfileImage ? this.uploadedProfileImage : null)
      return this.$axios
        .post(`user/profile/${id}`, formData)
        .then(() => {
          showToast('Edit Profile', 'Profile has been edited successfully!')
        }).catch(({
          response: {
            data: {
              statusCode, message,
            },
          },
        }) => {
          if (showErrorMessage(statusCode)) {
            showToast('Edit Profile', message.toString(), 'danger')
          }
        })
    },
  },
}
</script>
<style scoped>
 .add{
  position: relative;
}
.addprofile{
width: 175px;
height: 175px;
border-radius: 88px !important;
filter: drop-shadow(0px 6px 16px rgba(0,0,0,0.06));
border: 4px solid #ffffff;
}
.upload{
  position: absolute;
  bottom: 1px;
  right: 54px;
}
.label{
font-size: 14px;
color: #5e5873;
font-weight: 600;
font-family: "Montserrat";
}
.button{
  width: 300px;
  height: auto;
 border-radius: 5px !important;
}.link{
 text-decoration: underline !important;
font-size: 14px;
line-height: 22px;
text-decoration: underline;
color: red;
font-weight: 500;
font-family: "Montserrat";
text-align: center;
}
</style>
